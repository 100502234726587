import { Fragment, useState } from 'react'
import './Modal.scss'

const Modal = ({ content, options: {
    closeButton = true,
    darkMode = false
 }}) => {

    const [isOpen, setIsOpen] = useState(true)

    if (isOpen)
        return <Fragment>
            <div
                //Background of the modal
                className={`modal-background ${darkMode && 'dark'}`}
                // If the close button is hidden, the user can close the modal
                // by clicking in the background (Outside the modal)
                onClick={() => !closeButton && setIsOpen(false)}
                style={{ cursor: closeButton ? 'auto' : 'pointer' }}
            />

            <div /* Modal container/card/wrapper */ className={`modal ${darkMode && 'dark'}`}>
                {content}
            </div>

            {
                closeButton
                ? <button onClick={() => setIsOpen(false)} className={`modal-close-button ${darkMode && 'dark'}`}>✖</button>
                : null
            }
        </Fragment>

    return null
}

export default Modal
