import './App.css';
import React, { useState } from 'react';
import { getTao } from 'libtao';
import { register } from 'swiper/element/bundle'
import createModal from './components/Modal'


function App() {
  const [tao, setTao] = useState({});
  const [militaryTao, setMilitaryTao] = useState({});
  let interval = window.setInterval(refreshTao, 1000);

  register();

  function refreshTao() {
    setTao(getTao());
    setMilitaryTao(getTao(true));
    clearInterval(interval);
  }

  function openModal(text) {
    createModal(<p>{ text }</p>, {
      darkMode: true,
      closeButton: false
    });
  }

  function formatTwennyFour(date) {
    var time = ""

    if (date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
  
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
  
      time = hours + ':' + minutes + ':' + seconds;
    }
    
    return time;
  }

  function formatAmPm(date) {
    var time = ""
    
    if (date !== undefined) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      var ampm = hours >= 12 ? 'pm' : 'am';

      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      time = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    }

    return time;
  }

  return (
    <div id="app" className="App">
      <body>
        <swiper-container
          slides-per-view="1"
          grid-rows="1"
          mousewheel-force-to-axis="true"
          pagination="true">
          <swiper-slide>
            <p className="Time">{ formatAmPm(tao.date) }</p>
            <div className="TimeContainer">
              <p className="Time" onClick={() => openModal(tao.hoursTaoContent) }>{tao.hoursTao}</p>
              <p className="Time" onClick={() => openModal(tao.minutesTaoContent) }>{tao.minutesTao}</p>
              <p className="Time" onClick={() => openModal(tao.secondsTaoContent) }>{tao.secondsTao}</p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <p className="Time">{ formatTwennyFour(militaryTao.date) }</p>
            <div className="TimeContainer">
              <p className="Time" onClick={() => openModal(militaryTao.hoursTaoContent) }>{militaryTao.hoursTao}</p>
              <p className="Time" onClick={() => openModal(militaryTao.minutesTaoContent) }>{militaryTao.minutesTao}</p>
              <p className="Time" onClick={() => openModal(militaryTao.secondsTaoContent) }>{militaryTao.secondsTao}</p>
            </div>
          </swiper-slide>
        </swiper-container>
      </body>
    </div>
  );
}

export default App;
